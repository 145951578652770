<template>
  <content-layout :loading="loading" :breadcrumbs-items="breadcrumbsItems">
    <v-tabs v-model="filtersState.tab" style="border-radius: 8px">
      <v-tab v-for="item in tabs" :key="item.text">
        {{ item.text }}
      </v-tab>
    </v-tabs>
    <v-toolbar flat color="background" class="mt-2">
      <v-spacer />
      <cz-button
        :title="$t('common.filters')"
        :icon-src="
          hasActiveFilters(filtersState.filters)
            ? mdiFilterCheckOutline
            : mdiFilterOutline
        "
        :outlined="!hasActiveFilters(filtersState.filters)"
        :color="
          hasActiveFilters(filtersState.filters) ? 'accent darken-1' : 'primary'
        "
        @click="showFiltersBar = !showFiltersBar"
        :loading="loadingFilters"
      />
    </v-toolbar>
    <cz-data-table
      class="mt-2"
      height="calc(100vh - 370px)"
      v-bind="submissionsTable"
      :options.sync="filtersState.options"
      @click:row="onSubmissionClicked"
    >
      <template #item.relatedOrder="{ item }">
        <cz-button
          :title="item.relatedOrder.orderNumber"
          text
          color="primary"
        />
      </template>
      <template #item.issueDate="{ item }">
        <span>{{ formatDateToDisplay(item.issueDate) }}</span>
      </template>
      <template #item.createdAt="{ item }">
        <span>{{ formatDateToDisplay(item.createdAt) }}</span>
      </template>
      <template #item.totalAmount="{ item }">
        {{ formatAmountWithCurrency(item.totalAmount, item.currency) }}
      </template>
      <template #item.vatAmount="{ item }">
        {{ formatAmountWithCurrency(item.vatAmount, item.currency) }}
      </template>
      <template #item.netAmount="{ item }">
        {{ formatAmountWithCurrency(item.netAmount, item.currency) }}
      </template>
      <template #item.status="{ item }">
        {{ orderSubmissionStatusText[item.status] }}
      </template>
      <template #item.vendor="{ item }">
        <vendor-info-menu v-if="item.vendor" :vendor="item.vendor" />
      </template>
      <template #item.relatedOrder="{ item }">
        <order-info-menu
          v-if="item.relatedOrder"
          :order="item.relatedOrder"
          @show-order="onShowFullOrder(item)"
          show-order-link
        />
      </template>
      <template #item.approvalRound="{ item }">
        <cz-button
          :title="$t('customer.orders.viewApprovalRound')"
          color="primary"
          small
          @click.stop="showApprovalRoundForItem(item)"
          :icon-src="mdiCheckAll"
        />
      </template>
      <template #item.approverUserId="{ item }">
        <user-info-menu :user="item.approverUser" v-if="item.approverUser" />
      </template>

      <template #item.actions>
        <cz-icon :size="26" :src="mdiChevronLeft" color="textPrimary" />
      </template>
    </cz-data-table>

    <filters-bar
      v-if="filtersState.filters"
      v-model="showFiltersBar"
      @close="showFiltersBar = false"
      @on-filter="onFilter"
      :filter-fields="filtersState.filters"
    />
  </content-layout>
</template>

<script>
import { getOrderSubmissions, getUserRoles } from '@/core/api';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import { CzDataTable, CzButton, CzMenuButton, CzIcon } from '@/components';
import { formatDateToDisplay } from '@/shared/services/dates.service';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';
import { userHasRoleWithName } from '@/shared/services/rbac/rbac.service';
import RoleType from '@/shared/types/RoleType';
import UserInfoMenu from '@/domain/auth/components/UserInfoMenu.vue';
import {
  mdiDraw,
  mdiFileEyeOutline,
  mdiChevronLeft,
  mdiFilterOutline,
  mdiFilterCheckOutline,
  mdiNumeric,
  mdiAccountCardOutline,
  mdiCalendarRangeOutline,
  mdiTagMultipleOutline,
  mdiAccount,
  mdiCheckAll
} from '@mdi/js';
import OrderSubmissionStatus from '@/shared/types/OrderSubmissionStatus';
import { DecisionType } from '../components/SubmissionDecisionDialog.vue';
import { typesMixin } from '@/shared/mixins';
import VendorInfoMenu from '../components/VendorInfoMenu.vue';
import OrderInfoMenu from '../components/OrderInfoMenu.vue';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import store from '@/core/store';

import {
  formatFiltersForServer,
  hasActiveFilters
} from '@/shared/services/filters.service';

export default {
  name: 'CustomerSubmissionsPage',
  components: {
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    FiltersBar: () => import('@/domain/shared/components/FiltersBar.vue'),
    VendorInfoMenu,
    OrderInfoMenu,
    CzDataTable,
    CzButton,
    CzIcon,
    CzMenuButton,
    UserInfoMenu
  },
  props: {
    selectedTab: {
      type: Number,
      default: 0
    }
  },
  mixins: [typesMixin],
  created() {
    if (this.filtersState.tab === -1) {
      this.filtersState.tab = this.selectedTab || 0;
    }

    if (this.filtersState.filters === null) {
      this.initializeFilters();
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name !== 'customer-submission') {
      store.dispatch('orderSubmissions/resetFiltersState', {}, { root: true });
    }
    next();
  },
  computed: {
    ...mapFields('auth', ['accountId', 'currentUser', 'account']),
    ...mapFields('orderSubmissions', ['filtersState']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'customer'
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('customer.submissionsPageTitle'),
          disabled: true,
          exact: true
        }
      ];
    },
    userIsAuthorizer() {
      return userHasRoleWithName(
        this.currentUser,
        RoleType.CUSTOMER_AUTHORIZER
      );
    },
    userIsPurchaser() {
      return userHasRoleWithName(this.currentUser, RoleType.CUSTOMER_PURCHASER);
    },
    userIsObserver() {
      return userHasRoleWithName(this.currentUser, RoleType.CUSTOMER_OBSERVER);
    },
    submissionStatus() {
      if (this.filtersState.tab === 0) {
        return [OrderSubmissionStatus.PENDING_APPROVAL];
      } else if (this.filtersState.tab === 1) {
        return [
          OrderSubmissionStatus.PENDING_APPROVAL,
          OrderSubmissionStatus.APPROVED,
          OrderSubmissionStatus.REJECTED,
          OrderSubmissionStatus.RETURNED
        ];
      } else {
        return null;
      }
    }
  },
  data() {
    return {
      mdiCheckAll,
      mdiChevronLeft,
      mdiDraw,
      mdiFileEyeOutline,
      mdiFilterOutline,
      mdiFilterCheckOutline,
      loading: false,
      approvers: [],
      selectedSubmission: null,
      reviewSubmissionDialog: {
        show: false,
        saving: false,
        sending: false,
        orderSubmission: null,
        readOnlyMode: true,
        decisionMode: true
      },
      showFiltersBar: false,
      loadingFilters: false,
      filtersLoaded: false,
      tabs: [
        {
          id: 'pending-submissions',
          text: this.$t('customer.vendorSubmissions.pendingSubmissionsTitle'),
          excludeColumns: [],
          actions: [],
          status: 'open'
        },
        {
          id: 'handeled-submissions',
          text: this.$t('customer.vendorSubmissions.handeledSubmissionsTitle'),
          excludeColumns: [],
          actions: [],
          status: 'closed'
        }
      ],
      submissionsTable: {
        loading: false,
        serverItemsLength: -1,
        columns: [
          {
            text: this.$t('customer.vendorSubmissions.invoiceNumber'),
            value: 'documentNumber',
            sortable: true,
            width: 150
          },
          {
            text: this.$t('customer.vendorSubmissions.submissionStatus'),
            value: 'status',
            sortable: true,
            width: 150
          },
          {
            text: this.$t('customer.vendorSubmissions.shortDesc'),
            value: 'shortDesc',
            sortable: false,
            width: 120
          },
          {
            text: this.$t('customer.vendorSubmissions.vendorName'),
            value: 'vendor',
            sortable: false,
            width: 120
          },
          {
            text: this.$t('vendor.orderSubmissions.netAmount'),
            value: 'netAmount',
            sortable: false
          },
          {
            text: this.$t('vendor.orderSubmissions.vatAmount'),
            value: 'vatAmount',
            sortable: false
          },
          {
            text: this.$t('vendor.orderSubmissions.totalAmount'),
            value: 'totalAmount',
            sortable: false
          },
          {
            text: this.$t('vendor.orderSubmissions.issueDate'),
            value: 'issueDate',
            sortable: true
          },
          {
            text: this.$t('vendor.orderSubmissions.creationDate'),
            value: 'createdAt',
            sortable: true
          },
          {
            text: this.$t('vendor.orderSubmissions.relatedOrder'),
            value: 'relatedOrder',
            sortable: false
          },
          {
            text: this.$t('customer.orders.pendingApprovalBy'),
            value: 'approverUserId',
            width: 200
          },
          // {
          //   text: this.$t('customer.orders.approvalRound'),
          //   value: 'approvalRound'
          // },
          {
            text: '',
            value: 'actions',
            sortable: false,
            align: 'center'
          }
        ],
        items: []
      }
    };
  },
  methods: {
    hasActiveFilters,
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    formatDateToDisplay,
    formatAmountWithCurrency,
    async loadData() {
      try {
        this.submissionsTable.items = [];
        this.submissionsTable.loading = true;

        // get all submissions for list of orders
        const { itemsPerPage, page, sortBy, sortDesc } =
          this.filtersState.options;

        const $skip = (page - 1) * itemsPerPage;
        // const orderIds = orders.map((item) => item._id);

        const query = {
          customerAccountId: this.accountId,
          status: { $in: this.submissionStatus },
          $populate: ['vendor'],
          $limit: itemsPerPage,
          $skip
        };

        if (sortBy?.length) {
          query.$sort = {
            [`${sortBy[0]}`]: sortDesc[0] === true ? -1 : 1
          };
        }

        if (this.filtersState.filters?.length) {
          const formattedFilters = formatFiltersForServer(
            cloneDeep(this.filtersState.filters)
          );
          formattedFilters.forEach((field) => {
            query[field.fieldname] = field.value;
          });
        }

        if (!this.userIsObserver) {
          if (this.filtersState.tab === 0) {
            query.approverUserId = this.currentUser._id;
          } else if (this.filtersState.tab === 1) {
            query.handeledByUserIds = { $in: [this.currentUser._id] };
            query.approverUserId = { $ne: this.currentUser._id };
            query.approversUserIds = { $in: [this.currentUser._id] };
          }
        }

        // get all submissions for the given orders
        const { data: submissions, total: submissionsCount } =
          await getOrderSubmissions(query);
        this.submissionsTable.serverItemsLength = submissionsCount;
        this.submissionsTable.items = submissions;
      } finally {
        this.submissionsTable.loading = false;
      }
    },
    async loadSubmissionsFilters() {
      const { data: userRoles } = await getUserRoles({
        accountId: this.accountId,
        role: {
          $in: [RoleType.CUSTOMER_AUTHORIZER, RoleType.CUSTOMER_PURCHASER]
        },
        $populate: ['user']
      });

      this.approvers = userRoles
        .filter((item) =>
          [RoleType.CUSTOMER_AUTHORIZER, RoleType.CUSTOMER_PURCHASER].includes(
            item.role
          )
        )
        .map((item) => item.user);

      this.filtersLoaded = true;
    },
    onFilter(filters) {
      this.showFiltersBar = false;
      if (!isEqual(this.filtersState.filters, filters)) {
        this.filtersState.filters = cloneDeep(filters);
        this.loadData();
      }
    },

    initializeFilters() {
      const initialFilters = [
        {
          fieldname: 'relatedOrderNumber',
          label: this.$t('customer.orders.filters.orderNumber'),
          type: 'input',
          inputType: 'number',
          icon: mdiNumeric,
          value: ''
        },
        {
          fieldname: 'vendorId',
          label: this.$t('customer.orders.filters.vendor'),
          type: 'vendors',
          items: [],
          icon: mdiAccountCardOutline,
          value: ''
        },
        {
          fieldname: 'issueDate',
          label: this.$t('customer.orders.filters.issueDate'),
          type: 'datepicker',
          value: '',
          rules: 'date_format:dd/MM/yyyy',
          icon: mdiCalendarRangeOutline,
          multiple: false,
          operator: 'ge'
        },
        {
          fieldname: 'approversUserIds',
          label: this.$t('customer.orders.approvers'),
          type: 'autocomplete',
          itemText: 'fullName',
          itemValue: '_id',

          itemsHandler: () => {
            return this.approvers;
          },
          value: [],
          multiple: true,
          icon: mdiAccount,
          operator: 'in'
        },
        {
          fieldname: 'tags',
          label: this.$t('customer.orders.filters.tags'),
          type: 'tags',
          items: this.account.systemTags,
          value: [],
          icon: mdiTagMultipleOutline,
          operator: 'in'
        }
      ];

      this.filtersState.filters = cloneDeep(initialFilters);
    },

    relatedOrderMenuItems(item) {
      if (!item.relatedOrder) {
        return [];
      }

      const items = [
        {
          title: this.$t('customer.orders.orderNumber'),
          subtitle: item.relatedOrder.orderNumber
        },
        {
          title: this.$t('customer.orders.vendorId'),
          subtitle: item.relatedOrder.vendorId
        },
        {
          title: this.$t('customer.orders.issueDate'),
          subtitle: formatDateToDisplay(item.relatedOrder.issueDate)
        },
        {
          title: this.$t('customer.orders.totalAmount'),
          subtitle: formatAmountWithCurrency(item.relatedOrder.totalAmount)
        }
      ];

      if (item.relatedOrder.description) {
        items.push({
          title: this.$t('customer.orders.shortDesc'),
          subtitle: item.relatedOrder.description
        });
      }

      items.push({
        title: this.$t('customer.orders.orderDocument'),
        subtitle: this.$t('customer.orders.viewOrderDocument'),
        handler: () => {
          window.open(item.relatedOrder.orderDocument.url, '_blank');
        }
      });

      return items;
    },
    onHandleSubmission(item) {
      this.reviewSubmissionDialog.orderSubmission = cloneDeep(item);
      // this.reviewSubmissionDialog.readOnlyMode = !this.canEditSubmission(item);
      this.reviewSubmissionDialog.decisionMode = this.canEditSubmission(item);
      this.reviewSubmissionDialog.show = true;
    },
    onSubmissionClicked(item) {
      this.$router.push({
        name: 'customer-submission',
        params: {
          submissionId: item._id
        }
      });
    },
    /**
     * Authorizer can edit submission only if submission status is pending autorizer
     * Purchaser can edit submission only when submission status is pedning purchaser
     * In all other statuses, submission cannot be editable by the customer
     */
    canEditSubmission(item) {
      return (
        (this.userIsAuthorizer &&
          item.status === OrderSubmissionStatus.PENDING_AUTHORIZER) ||
        (this.userIsPurchaser &&
          item.status === OrderSubmissionStatus.PENDING_PURCHASER)
      );
    },
    onSubmissionDecision(decisionType) {
      // remove order submission from list
      const index = this.submissionsTable.items.findIndex(
        (item) => item._id === this.reviewSubmissionDialog.orderSubmission._id
      );
      if (index !== -1) {
        this.submissionsTable.items.splice(index, 1);
      }
      this.reviewSubmissionDialog.show = false;
      switch (decisionType) {
        case DecisionType.APPROVE:
          this.showSuccessToastMessage(
            this.$t('customer.vendorSubmissions.submissionApprovedSuccess')
          );
          break;
        case DecisionType.REJECT:
          this.showSuccessToastMessage(
            this.$t('customer.vendorSubmissions.submissionRejectedSuccess')
          );
          break;
        case DecisionType.RETURN:
          this.showSuccessToastMessage(
            this.$t('customer.vendorSubmissions.submissionReturnedSuccess')
          );
          break;
      }
    },
    showApprovalRoundForItem() {},
    onShowFullOrder(submission) {
      const { href } = this.$router.resolve({
        name: 'customer-order',
        params: {
          id: submission?.relatedOrderId
        }
      });
      window.open(href, '_blank');
    }
  },
  watch: {
    'filtersState.tab': {
      handler() {
        this.loadData();
      }
    },
    'filtersState.options': {
      handler() {
        this.loadData();
      }
    },
    showFiltersBar: {
      handler(value) {
        if (value && !this.filtersLoaded) {
          this.loadSubmissionsFilters();
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
// ::v-deep tbody {
//   tr:hover {
//     background-color: transparent !important;
//   }
// }
::v-deep tbody tr :hover {
  cursor: pointer;
}
</style>
